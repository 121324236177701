<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="12" sm="12" md="6" lg="3">
          <v-menu
            v-model="menuStartDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                flat
                dense
                background-color="white"
                v-model="params.startDate"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="params.startDate"
              @input="menuStartDate = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="3">
          <v-menu
            v-model="menuEndDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                flat
                dense
                background-color="white"
                v-model="params.endDate"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="params.endDate" @input="menuEndDate = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="1">
          <v-btn color="primary" @click="getReports">
            <v-icon>
              mdi-magnify
            </v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="1">
          <v-btn color="success" @click="excel">
            <v-icon>
              mdi-file-excel
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-data-table :headers="headers" :items="items" dense>
        <template v-slot:item.no="{ item }">
          {{ items.map(x => x).indexOf(item) + 1 }}
        </template>
        <template v-slot:item.toIdr="{ item }">
          {{ formatPrice(item.toIdr) }}
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";
import fileDownload from "js-file-download";

export default {
  name: "report-currency",
  data() {
    return {
      items: [],
      params: {
        startDate: moment()
          .clone()
          .startOf("month")
          .format("yyyy-MM-DD"),
        endDate: moment()
          .clone()
          .endOf("month")
          .format("yyyy-MM-DD"),
      },
      menuStartDate: false,
      menuEndDate: false,
      headers: [
        {
          text: "No",
          align: "center",
          sortable: false,
          value: "no",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "Tanggal",
          value: "date",
          sortable: false,
          align: "center",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "Currency",
          value: "code",
          sortable: false,
          align: "center",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "Rate",
          value: "toIdr",
          sortable: false,
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
      ],
    };
  },
  methods: {
    async getReports() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("report/currency", this.params)
        .then(response => {
          this.items = response.data;
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    async excel() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("report/currencyExcel", this.params)
        .then(response => {
          fileDownload(response.data, `report-currency.xlsx`);
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>

<style></style>
